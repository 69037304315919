import { createSelector } from 'reselect'
import { Science } from '../../models/science'
import { RootState } from '../types'
import { State } from './types'

const scienceSelector = (state: RootState): State => state.science

export const getList = createSelector(
  scienceSelector,
  (state: State): Science[] => state.list
)

export const getIsListLoading = createSelector(
  scienceSelector,
  (state: State): boolean => state.isListLoading
)

export const selectors = {
  getList,
  getIsListLoading
}
