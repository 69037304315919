import { connect } from 'react-redux'
import { Science as Base, Props, Handlers } from './Science'
import { RootState } from '../../state/types'
import { getList } from '../../state/science/selectors'
import { getList as getHistory } from '../../state/history/selectors'
import { fetchList } from '../../state/science/operations'

const mapStateToProps = (state: RootState): Props => ({
  science: getList(state)
})

const mapDispatchToProps: Handlers = {
  fetchList
}

export const Science = connect(mapStateToProps, mapDispatchToProps)(Base)
