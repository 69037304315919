import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Reveal } from 'react-genie'
import { customMedia } from '../common/customMedia'
import { parse } from 'querystring'
import { Science as ScienceModel } from '../../models/science'
import { Image } from '../common/Image'
import { useTranslation, Trans } from 'react-i18next'

export interface Props {
  science: ScienceModel[]
}

export interface Handlers {
  fetchList: (draftKey: string) => void
}

export const Science = (props: Props & Handlers ) => {
  const { science } = props
  const { fetchList  } = props

  const [t, i18n] = useTranslation()

  useEffect(() => {
    const query = parse(window.location.search.slice(1))
    fetchList(query.draftKey as string)
  }, [])

  return (
    <>
      <Container>
        <Wrapper>
          <Reveal delay={500}>
            <ScienceIconWrapper>
              <Image path="science@2x.png" />
            </ScienceIconWrapper>
          </Reveal>

          {science.map((s, i) => (
            <ScienceWrapper key={i}>
              <Reveal delay={500}>
                <Title>{i18n.language === 'ja' ? s.title: s.enTitle}</Title>
              </Reveal>
              <Reveal delay={500}>
                <Content dangerouslySetInnerHTML={{__html: s.content}} />
              </Reveal>
            </ScienceWrapper>
          ))}
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding-top: 80px;
  `}
  ${customMedia.greaterThan("medium")`
    padding-top: 100px;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px 20px;
  `}
  ${customMedia.between("medium", "large")`
    margin: 0 auto;
    padding: 70px 0;
    width: 735px;
  `}
  ${customMedia.greaterThan("large")`
    margin: 0 auto;
    padding: 70px 0;
    width: 1000px;
  `}
`

const ScienceIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto 25px;
    width: 72px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto 60px;
    width: 144px;
  `}
`

const ScienceWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-bottom: 60px;
  `}
`

const Title = styled.h1`
  ${customMedia.lessThan("medium")`
    font-size: 1.25rem;
    margin-bottom: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.5rem;
    margin-bottom: 30px;
  `}

  color: #26d2ad;
  font-weight: bold;
`

const Content = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
  `}

  color: #60656b;
`

const Article = styled.h2`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    margin-bottom: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
    margin-bottom: 60px;
  `}
`