import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { Science } from '../components/Science'

const IndexPage = () => (
  <Layout>
    <SEO
      title="遠隔医療で健幸へ導くリモハブのサイエンス"
      description="運動療法を核とした心臓リハビリの効果等につきまして、Remohab（リモハブ）の在宅リハビリに関する資料・文献目録を公開しています。"
      keywords={['心疾患','リハビリ','遠隔診療','参考資料','文献','リモハブ','remohab']}
    />
    <H1>心疾患　リハビリ　遠隔診療　参考資料　文献 | Remohab リモハブ</H1>
    <Science />
  </Layout>
)

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default IndexPage
